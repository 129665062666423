import React, { useState, useRef, useContext } from 'react';
import { MenuTab, MenuTabs } from './MenuTabs'
import styles from './MenuTabs.module.css'
import Stage1 from './Stage1';
import Stage2 from './Stage2';
import Stage3 from './Stage3';
import Stage4 from './Stage4';
import Stage5 from './Stage5';
import Stage6 from './Stage6';
import Stage7 from './Stage7';

import MenuContext from './MenuContext';


function Workflowtabs() {

  const { activeTab, setActiveTab } = useContext(MenuContext);

  const changeMenu = (menu) => {
    console.log('changeMenu renders')
    setActiveTab(menu);
  }

  console.log('workflow tab renders')
  return (
    <div className='container-fluid createworkflowsection2'>
      <div className='container mycontainer'>
        <MenuTabs name="Workflowtab" changeHandler={changeMenu} tabState={{activeTab : activeTab}} style={styles} >
          <MenuTab label='Stage1' name='Initialize Workflow' key='1'>
            <Stage1 />
          </MenuTab>
          <MenuTab label='Stage2' name='Temporal Settings' key='2'>
            <Stage2 />
          </MenuTab>
          <MenuTab label='Stage3' name='Data Selection' key='3'>
            <Stage3 />
          </MenuTab>
          <MenuTab label='Stage4' name='Data Availability' key='4'>
            <Stage4 />
          </MenuTab>
          <MenuTab label='Stage5' name='Promotions' key='5'>
            <Stage5 />
          </MenuTab>
          <MenuTab label='Stage6' name='Model Selection' key='6'>
            <Stage6 />
          </MenuTab>
          <MenuTab label='Stage7' name='Review & Launch Workflow' key='7'>
            <Stage7 />
          </MenuTab>
        </MenuTabs>
      </div>
    </div>
  )
}

export default Workflowtabs
