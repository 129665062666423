import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../components/common/Breadcrumb';
import Banner from '../components/createworkflow/Banner';
import Workflowtabs from '../components/createworkflow/Workflowtabs';
import MenuContext from '../components/createworkflow/MenuContext';
import StageNavigator from '../components/createworkflow/StageNavigator';

function CreateWorkflow() {
  const location = useLocation(); // Access location object
  const { pageName } = location.state; // Extract passed state data

  const [activeTab, setActiveTab] = useState('Stage1');


  return (
    <>
      <MenuContext.Provider value={{ activeTab, setActiveTab }}>
        <Breadcrumb PageName={pageName} />
        <Banner />
        <Workflowtabs />
        <StageNavigator />
      </MenuContext.Provider>

    </>
  )
}

export default CreateWorkflow;
