import React from 'react'

export default function Stage2() {
  return (
    <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="createworkflowtabcontents">
            <div id="stage1">
            </div>
            <div id="stage2">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="creatnote">* Data available for forecast is between 05-02-2024 to 01-04-2024</div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 padding">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group1">
                           <label for="email">Start Date<span>*</span></label>
                           <input type="email" class="form-control" id="email" placeholder="DD/MM/2024" />
                           <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group1">
                           <label for="email">End Date<span>*</span></label>
                           <input type="email" class="form-control" id="email" placeholder="DD/MM/2024" />
                           <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group1">
                           <label for="email">Test Window<span>*</span></label>
                           <input type="email" class="form-control" id="email" placeholder="Enter Here" />
                          <div class="txt1">Test window Cannot Be Less Then 13 Weeks</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 padding">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group1">
                           <label for="email">Forecast Horizon<span>*</span></label>
                           <input type="email" class="form-control" id="email" placeholder="DD/MM/2024" />
                           <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 space">
                    <div class="form-group1">
                        <label class="radio-inline"><input type="radio" name="optradio" defaultChecked /> Daily</label>
                        <label class="radio-inline"><input type="radio" name="optradio" />Weekly</label>
                        <label class="radio-inline"><input type="radio" name="optradio" />Monthly</label>
                        <label class="radio-inline"><input type="radio" name="optradio" />Quarterly</label>
                    </div> 
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group1">
                        <label for="email">Dealer Promotions</label>
                        <div class="temporalpopupbtn">
                            <ul>
                                <li><a href="#dealerpromotion" class="popup-btn">FOC <iconify-icon icon="iconamoon:arrow-right-2-thin"></iconify-icon></a></li>
                                <li><a href="#dealerpromotion" class="popup-btn">ED <iconify-icon icon="iconamoon:arrow-right-2-thin"></iconify-icon></a></li>
                                <li><a href="#dealerpromotion" class="popup-btn">X1 <iconify-icon icon="iconamoon:arrow-right-2-thin"></iconify-icon></a></li>
                                <li><a href="#dealerpromotion" class="popup-btn">X2 <iconify-icon icon="iconamoon:arrow-right-2-thin"></iconify-icon></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>
  )
}
