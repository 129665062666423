import React from 'react'
import Breadcrumb from '../components/common/Breadcrumb';

function MyProfile() {
  return (
    <div>
     <Breadcrumb  PageName={'My Profile'} />

    </div>
  )
}

export default MyProfile;
