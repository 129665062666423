
import {img1} from '../assets/images'

const KeyBenefitsData = [
    {
        Name: 'Revenue Planning',
        Paragraph1 : 'Revenue Planning Sales forecasting enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management ',
        Paragraph2 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate',
        Image : img1
    },
    {
        Name: 'Resource Allocation',
        Paragraph1 : 'Resource Allocation Sales forecasting enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management ',
        Paragraph2 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate',
        Image : img1
    },
    {
        Name: 'Cash Flow Management',
        Paragraph1 : 'Cash Flow Management enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management ',
        Paragraph2 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate',
        Image : img1
    },
    {
        Name: 'Marketing Strategy',
        Paragraph1 : 'Marketing Strategy Sales forecasting enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management ',
        Paragraph2 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate',
       Image : img1
    },
    {
        Name: 'Risk Mitigation',
        Paragraph1 : 'Risk Mitigation Sales forecasting enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management ',
        Paragraph2 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate',
       Image : img1
    },
    {
        Name: 'Setting Realistic Targets',
        Paragraph1 : 'Setting Realistic Targets Sales forecasting enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management ',
        Paragraph2 : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate',
        Image : img1
    }
]

export { KeyBenefitsData }