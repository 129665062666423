import React from 'react';
import '../../assets/css/viewtrree.css';

export default function Stage3() {
  return (  
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="createworkflowtabcontents">
                    <div id="stage1">
                    </div>
                    <div id="stage2">
                        
                    </div>
                    <div id="stage3">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <ul class="datatabselect" data-persist="true">
                                <li><a aria-label="DE">PRODUCT HEIRARCHY</a> </li>
                                <li><a aria-label="DE">GEOGRAPHY</a></li>
                                <li><a aria-label="DE">CHANNEL</a></li>
                            </ul>
                            <div class="datatab">
                                <div id="productheirarchy">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="productbox1">
                                            <h4>HEIRARCHY</h4>
                                            <div class="viewtree">
                                                <ul>
                                                    <li>
                                                    <span class="caretnavi">Food</span>
                                                      <ul class="nested">
                                                        <li>
                                                            <span class="caretnavi">Beverages</span>
                                                            <ul class="nested">
                                                              <li>Water</li>
                                                              <li>Coffee</li>
                                                              <li>
                                                                <span class="caretnavi">Tea</span>
                                                                <ul class="nested displaytab" data-persist="true">
                                                                  <li><a href="#pro1" aria-label="DE">Black Tea</a></li>
                                                                  <li><a href="#pro2" aria-label="DE">White Tea</a></li>
                                                                  <li><a href="#pro3" aria-label="DE">
                                                                    <span class="caretnavi">Green Tea</span>
                                                                    <ul class="nested">
                                                                      <li>Sencha</li>
                                                                      <li>Gyokuro</li>
                                                                      <li>Matcha</li>
                                                                      <li>Pi Lo Chun</li>
                                                                    </ul>
                                                                </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                            </ul>
                                                          </li>
                                                        <li> <span class="caretnavi">Combo Meal</span>
                                                            <ul class="nested">
                                                                <li>Sub</li>
                                                                <li>Sub</li>
                                                                <li>Sub</li>
                                                            </ul>
                                                        </li>
                                                        <li> <span class="caretnavi">Package Food</span>
                                                            <ul class="nested">
                                                                <li>Sub</li>
                                                                <li>Sub</li>
                                                                <li>Sub</li>
                                                            </ul>
                                                        </li>
                                                        <li> <span class="caretnavi">Rice</span>
                                                            <ul class="nested">
                                                                <li>Sub</li>
                                                                <li>Sub</li>
                                                                <li>Sub</li>
                                                            </ul>
                                                        </li>
                                                      </ul>
                                                    </li>
                                                    <li>
                                                        <span class="caretnavi">Machine</span>
                                                        <ul class="nested">
                                                            <li>Sub</li>
                                                            <li>Sub</li>
                                                            <li>Sub</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <span class="caretnavi">Clothing</span>
                                                        <ul class="nested">
                                                            <li>Sub</li>
                                                            <li>Sub</li>
                                                            <li>Sub</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <span class="caretnavi">Electronic</span>
                                                        <ul class="nested">
                                                            <li>Sub</li>
                                                            <li>Sub</li>
                                                            <li>Sub</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="productbox1">
                                            <h4>SKU’s</h4>
                                            <div class="dataselectsearhbar">
                                                <input type="email" class="form-control" id="search" placeholder="Search Here" />
                                                <iconify-icon icon="material-symbols:search"></iconify-icon>
                                            </div>
                                            <div class="displayproducttab">
                                                <div id="pro1">
                                                
                                                </div>
                                                <div id="pro2">
                                                  
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="productbox2">
                                            <h4>SELECTED ITEM</h4>
                                            <div class="selectitemheight">
                                                <div class="selecteditem">
                                                    <ul>
                                                        <li>1.</li>
                                                        <li>Food</li>
                                                        <li>Beverages</li>
                                                        <li>Tea</li>
                                                        <li><a href="#">Green Tea</a></li>
                                                        <li>Sencha</li>
                                                    </ul>
                                                    <div><span><a href="#"><iconify-icon icon="typcn:delete-outline"></iconify-icon></a></span></div>
                                                </div>
                                                <div class="selecteditem">
                                                    <ul>
                                                        <li>2.</li>
                                                        <li>Food</li>
                                                        <li>Combo Meal</li>
                                                        <li>Daily Thali</li>
                                                    </ul>
                                                    <div><span><a href="#"><iconify-icon icon="typcn:delete-outline"></iconify-icon></a></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>   
  )
}
