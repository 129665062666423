import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import App from './test/App';
import UserService from "./services/UserService";
import HttpService from "./services/HttpService";
import RenderOnAuthenticated from './services/RenderOnAuthenticated';
import RenderOnAnonymous from './services/RenderOnAnonymous'
import Frontpage from './pages/FrontPage';



const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => root.render(
  <>
  <RenderOnAuthenticated>
    <App />
  </RenderOnAuthenticated>
  <RenderOnAnonymous>
    <Frontpage />
  </RenderOnAnonymous>
  </>

  
);

//below code renderApp without using keycloak authentication
renderApp(); 

//below code to renderApp using keycloak authentication 
//  UserService.initKeycloak(renderApp);
//  HttpService.configure();



