import React from 'react';
import * as Image from '../../assets/images'

export default function Footer() {
  return (
    <div>
      <div class="container-fluid copybg mainbgcolor">
        <div class="container mycontainer space3">
          <div class="row">
            <div class="col-md-11 col-sm-11 col-xs-12">
              <img src={Image.copyimg} class="max" alt="Demand Edge" /><br />©2023 Quation Solutions Pvt. Ltd., All rights reserved
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12">
              <p id="bottom">
                <a href="javascript:scrollToTop()" aria-label="DE">
                  <iconify-icon icon="tabler:arrow-top-circle" class="scrollerbottom animate__animated animate__infinite animate__slideInUp"></iconify-icon>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
