import React, { createRef, useRef } from 'react';
import '../../assets/css/css.css';
import * as images from '../../assets/images';
import AuthService from '../../services/AuthService';
import { displayMessage } from '../../Utils/helper';

export default function LoginPopup({ onClose, gotoSignUp }) {

  const usernameRef = useRef();
  const passwordRef = createRef();
  const agreementRef = useRef();

  const test = ()=> {
    displayMessage('success', 'Logged in Successfully', 'Logged in Successfully');
  }

  const submitLogin = () => {
    try {    
      if (agreementRef.current.checked) {
        AuthService.login({ username: usernameRef.current.value, password: passwordRef.current.value });
        displayMessage('success', 'Logged in Successfully', 'Logged in Successfully');
      }
      else {
        displayMessage('danger', 'Please accept Agreement', 'Terms and Conditions');
      }

    } catch(error) {
      displayMessage('danger', 'Please c Agreement', 'Terms and Conditions');
    }
  }

  return (
    <>
      {/* Overlay for faded background */}
      <div className="modal-overlay" onClick={onClose}></div>
      <div id="login" class="modal modal-login fade in" style={{ display: 'block' }} >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="motit">
                <button type="button" onClick={onClose} class="close"><iconify-icon icon="material-symbols:close"></iconify-icon></button>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="loginwhitebg">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="loginiconbg">
                      <img src={images.loginicon1} class="max" alt="Demand Edge" /><br />Login
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group1">
                      <input ref={usernameRef} type="text" class="form-control form-control1" id="username" placeholder="Enter Your Email Id" />
                    </div>
                    <div class="form-group1">
                      <input ref={passwordRef} type="password" class="form-control form-control1" id="password" placeholder="Enter Your Password" />
                    </div>
                    <div class="forgotpwd">
                      <a href="#" aria-label="DE">Forgot Password?</a>
                    </div>
                    <div class="form-group">
                      <div class="checkbox">
                        <label><input ref={agreementRef} type="checkbox" id="agreement" />I agree to the Terms of Service and Privacy Policy.</label>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="connectbtn">
                        <a onClick={submitLogin} href='#' aria-label="DE">Login <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>


                    </div>
                    <div class="noaccount">
                      <a href="#" aria-label="DE">Don’t Have An Account?</a>
                    </div>
                    <div class="createaccount">
                      <a onClick={gotoSignUp} className='open-signup' aria-label="DE">Create Account Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
