import { Store } from 'react-notifications-component';

function displayMessage(type, title, message) {
  console.error(message);
    // Store.addNotification({
    //   title: title,
    //   message: message,
    //   type: type,
    //   insert: "top",
    //   container: "bottom-left",
    //   animationIn: ["animate__animated", "animate__fadeIn"],
    //   animationOut: ["animate__animated", "animate__fadeOut"],
    //   dismiss: {
    //     duration: 5000,
    //     onScreen: false
    //   }
    // });
  }


  export {
    displayMessage
  }