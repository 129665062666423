import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumb({ PageName }) {

  function buildBreadcrumb(PageName) {
    switch (PageName) {
      case 'Dashboard': return (<><li>Dashboard</li></>);
      case 'Create Workflow': return (
        <>
          <li>
            <Link to="/Dashboard">Dashboard </Link>
          </li>
          <li>
             {PageName}
          </li>
        </>)
        default :return (
          <>
            <li>
              <Link to="/Dashboard">Dashboard </Link>
            </li>
            <li>
               {PageName}
            </li>
          </>)
    }
  }

  return (
    <div className="container-fluid aftrlogincontainer1 mainbgcolor">
      <div className="container mycontainer">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="breadcrumb">
              <ul>
                { buildBreadcrumb(PageName)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
