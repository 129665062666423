import React, { useState, useEffect } from 'react'
import * as images from '../assets/images'
import UserService from '../services/UserService'
import KeyBenefits from '../components/frontpage/KeyBenefits'
import FeaturesSlider from '../components/frontpage/FeaturesSlider'
import { createPortal } from 'react-dom';
import { postRequest } from '../services/DataRequestService';
import LoginPopup from '../components/frontpage/LoginPopup';
import SignupPopup from '../components/frontpage/SignupPopup'

import { displayMessage } from '../Utils/helper'

export default function Frontpage() {

  const [showLoginModal, setshowLoginModal] = useState(false);
  const [showSignupModal, setshowSignupModal] = useState(false);

  const test = ()=> {
    displayMessage('success', 'Logged in Successfully', 'Logged in Successfully');
  }


  useEffect(() => {

    // Array of script sources
    const scripts = [
      'js/jquery.min.js',
      'js/js.js',
      'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/owl.carousel.min.js',
      'js/bannerslider.js',
      'js/menu.js',
      'js/keybenfitstab.js',
      'js/jquery.flexslider.js'
    ];

    // Function to load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(src);
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    // Load all scripts in order
    const loadScriptsSequentially = async () => {
      try {
        for (const script of scripts) {
          await loadScript(script);
        }
        console.log('All scripts loaded successfully.');
      } catch (error) {
        console.error(error);
      }
    };

    loadScriptsSequentially();

    // Cleanup function to remove scripts on component unmount
    return () => {
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  const gotoLogin = () => {
    setshowSignupModal(false);
    setshowLoginModal(true);
  }

  const gotoSignUp = () =>{
    setshowSignupModal(true);
    setshowLoginModal(false);
  }

  return (
    <div>
  
      <div className="mainbannerbg"> </div>
      <div className="container-fluid header newheader">
        <div className="row">
          <div className="col-md-2 col-sm-4 col-xs-6 deskpadding">
            <div className="logo">
              <a href="http://demandedge.innovatorwebsolutions.com" aria-label="DE"><img src={images.logo} className="max" alt="Demand Edge"></img></a>
            </div>
          </div>
          <div className="col-md-8 col-sm-8 col-xs-6">
            <div className="mainmenu">
              <nav id="nav">
                <span id='menutoggle' className='test'><span>Menu</span></span>
                <ul className="clearfix">
                  <li><a href="#aboutus" className="scroll" aria-label="DE">About Us</a></li>
                  <li><a href="#keybenefits" className="scroll" aria-label="DE">Key Benefits</a></li>
                  <li><a href="#features" className="scroll" aria-label="DE">Features</a></li>
                  <li><a href="#contactus" className="scroll" aria-label="DE">Contact Us</a></li>
                  <li className="loginmenu">
                    <button onClick={() => { setshowLoginModal(true) }} aria-label="DE">
                      Login <iconify-icon icon="ant-design:login-outlined"></iconify-icon>
                    </button>
                  </li>
                  <li className="signupmenu"><button onClick={() => { setshowSignupModal(true) }} className="popup-btn" aria-label="DE">Signup <iconify-icon icon="hugeicons:lock-key"></iconify-icon></button></li>
                </ul>

              </nav>
            </div>
          </div>
          <div className="col-md-2 col-sm-4 col-xs-12">
            <div className="headcall">
              <iconify-icon icon="fluent:call-16-regular"></iconify-icon>
              <a href="tel:+919652415296" aria-label="DE"><span>Call :</span><br />+91 96524 15296</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bannerbg">
        <div className="container mybannercontainer">
          <div className="row">
            <div className="col-md-5 col-sm-6 col-xs-12">
              <div className="owl-carousel owl-theme fxSoftScale">
                <div className="item">
                  <h2>FOR THE<br /><span>FUTURE</span><br /><span><span>LIFE</span></span></h2>
                  <p>Predict the future and take meaningful action in the present</p>
                  <a href="#" aria-label="DE">Read More <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a>
                </div>
                <div className="item">
                  <h2>FOR THE<br /><span>FUTURE</span><br /><span><span>LIFE</span></span></h2>
                  <p>Predict the future and take meaningful action in the present</p>
                  <a href="#" aria-label="DE">Read More <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-6 col-xs-12">
              <div>
                <img src={images.banner1} className="max banner1" alt="Demand Edge"></img>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="socialmedia">
              <ul>
                <li><a href="#" aria-label="DE" target="_blank"><iconify-icon icon="ic:baseline-facebook"></iconify-icon></a></li>
                <li><a href="#" aria-label="DE" target="_blank"><iconify-icon icon="teenyicons:instagram-solid"></iconify-icon></a></li>
                <li><a href="#" aria-label="DE" target="_blank"><iconify-icon icon="mdi:linkedin"></iconify-icon></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid section1">
        <a href="#" aria-label="DE" id="aboutus" style={{
          float: 'left', color: '#ffffff', textDecoration: 'none',
          visibility: 'hidden', margin: '-100px 0 0 0'
        }}>Top </a>
        <div className="container mycontainer">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h4>About Us</h4>
              <h2>Precision Forecasting for<br /><span>Strategic Decision-Making</span></h2>
              <p>Demand Edge is your essential tool for precise forecasting, delivering actionable insights to drive strategic decision-making. With advanced algorithms and data analytics, Demand Edge empowers businesses to anticipate market trends and optimize resource allocation, ensuring sustainable growth and competitive advantage.</p>
              <div className="readmorebtn1"><a href="#" aria-label="DE">Read More<iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-md-offset-2 col-sm-6 col-xs-12">
              <div className="sec1box1">
                <h3>Data Analytics</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate</p>
                <div className="col-md-12 col-sm-12 col-xs-12 padding">
                  <div className="col-md-5 col-sm-4 col-xs-12 padding">
                    <img src={images.icon1} className="max" alt="Demand Edge"></img>
                  </div>
                  <div className="col-md-7 col-sm-8 col-xs-12 space1">
                    <div className="readmorebtn2"><a href="#" aria-label="DE">Get Started <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="sec1box1">
                <h3>Advanced Algorithms</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate</p>
                <div className="col-md-12 col-sm-12 col-xs-12 padding">
                  <div className="col-md-5 col-sm-4 col-xs-12 padding">
                    <img src={images.icon2} className="max" alt="Demand Edge"></img>
                  </div>
                  <div className="col-md-7 col-sm-8 col-xs-12 space1">
                    <div className="readmorebtn2"><a href="#" aria-label="DE">Get Started <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section2bg">
        <img src={images.bg1} className="max" alt="Demand Edge"></img>
      </div>
      <div className="container-fluid section2">
        <a href="#" aria-label="DE" id="keybenefits" style={{
          float: 'left',
          color: '#ffffff',
          textDecoration: 'none',
          visibility: 'hidden',
          margin: '-150px 0 0 0'
        }}>Top </a>
        <div className="container mycontainer">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h4>Key Benefits</h4>
              <h2>Everything Your Team Needs To<br /><span>Achieve Their Goals</span></h2>
              <p>Demand Edge is your essential tool for precise forecasting, delivering actionable insights to drive strategic decision-making. With advanced algorithms and data analytics, Demand Edge empowers businesses to anticipate market trends and optimize resource allocation, ensuring sustainable growth and competitive advantage.</p>
            </div>
          </div>
          <div className="row">
            <KeyBenefits />
          </div>
        </div>
      </div>
      <div className="container-fluid section3">

        <a href="#" aria-label="DE" id="features" style={{
          float: 'left',
          color: '#ffffff',
          textDecoration: 'none',
          visibility: 'hidden',
          margin: '-100px 0 0 0'
        }}>Top </a>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12 section3sub">
            <h4>Features</h4>
            <h2>Top Benefit Of using Demad Edge For<br /><span>Accurate Business Prediction</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate</p>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 padding">
            <FeaturesSlider />
          </div>
        </div>
      </div>
      <div className="container-fluid section5">
        <a href="#" id="contactus" style={{ float: 'left', color: '#ffffff', textDecoration: 'none', visibility: 'hidden', margin: '-100px 0 0 0' }}>Top </a>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12 padding">
            <div className="sec5box1">
              <h4>Enquire Now</h4>
              <h2>Want To Know More?<br /><span>Connect With Us</span></h2>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem</p>
            </div>
          </div>
          <img src={images.img2} className="img2" alt="Demand Edge"></img>
          <div className="col-md-6 col-sm-6 col-xs-12 padding">
            <div className="sec5box2">
              <h2>We’d love to hear <span>from you anytime</span></h2>
              <div className="formbg">
                <div className="col-md-12 col-sm-12 col-xs-12 padding space1">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group1">
                      <input type="email" className="form-control" id="email" placeholder="Enter Your Full Name"></input>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group1">
                      <input type="email" className="form-control" id="email" placeholder="Enter Your Email Id"></input>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 padding">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group1">
                      <input type="email" className="form-control" id="email" placeholder="Enter Your Current Organisation "></input>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group1">
                      <input type="email" className="form-control" id="email" placeholder="Enter Your Mobile No."></input>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <div className="checkbox">
                      <label><input type="checkbox" id="ipname" name="ipname"></input>I agree to the Terms of Service and Privacy Policy.</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="connectbtn"><a href="#">Connect With Me <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid section6">
        <div className="container mycontainer">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <h4>Our Skills</h4>
              <h2>We are master in<br /><span>data Analytics & Algorithms</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate</p>
              <div className="progress-section" data-aos="fade-left" data-aos-once="true">
                <div className="task-progress">
                  <p>Data Anaylsis
                    <span>88%</span>
                  </p>
                  <progress className="progress progress1" max="100" value="88"></progress>
                </div>
                <div className="task-progress">
                  <p>Advance Algorithms
                    <span>55%</span>
                  </p>
                  <progress className="progress progress1" max="100" value="55"></progress>
                </div>
                <div className="task-progress">
                  <p>AI Solutions
                    <span>92%</span>
                  </p>
                  <progress className="progress progress1" max="100" value="92"></progress>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <img src={images.v1} className="max" alt="Demand Edge"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid footerbg">
        <div className="container mycontainer">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <img src={images.ftrlogo} className="max ftrlogo" alt="Demand Edge"></img>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div className="ftrsocialmedia">
                <ul>
                  <li><a href="#" aria-label="DE" target="_blank"><iconify-icon icon="ic:baseline-facebook"></iconify-icon></a></li>
                  <li><a href="#" aria-label="DE" target="_blank"><iconify-icon icon="teenyicons:instagram-solid"></iconify-icon></a></li>
                  <li><a href="#" aria-label="DE" target="_blank"><iconify-icon icon="mdi:linkedin"></iconify-icon></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <h5>Company</h5>
              <div>
                <ul>
                  <li><a href="#aboutus" className="scroll" aria-label="DE">About Us</a></li>
                  <li><a href="#keybenefits" className="scroll" aria-label="DE">Key Benefits</a></li>
                  <li><a href="#features" className="scroll" aria-label="DE">Features</a></li>
                  <li><a href="#contactus" className="scroll" aria-label="DE">Contact Us</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <h5>Contact Us</h5>
              <div>
                <ul>
                  <li className="linehe"><iconify-icon icon="mdi:address-marker-outline"></iconify-icon> Quation Solutions Private Limited
                    6th Floor, Tower 3, WARP SJR I Park, EPIP Zone
                    Opp. Sri Sathya Sai Hospital Whitefield Bangalore</li>
                  <li><a href="mailto:quation@quation.in" target="_blank" aria-label="DE"><iconify-icon icon="tabler:mail"></iconify-icon> quation@quation.in</a></li>
                  <li><a href="tel:+91-9652415296" target="_blank" aria-label="DE"><iconify-icon icon="fluent:call-16-regular"></iconify-icon> +91-9652415296</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <h5>Get In Touch</h5>
              <div className="space1">
                <div className="form-group">
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email Adress"></input>
                </div>
                <div className="connectbtn"><a href="#" aria-label="DE">Connect With Me <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLoginModal && createPortal(
        <LoginPopup onClose={() => setshowLoginModal(false)} gotoSignUp={gotoSignUp} />,
        document.body
      )}
      {showSignupModal && createPortal(
        <SignupPopup onClose={() => setshowSignupModal(false)} gotoLogin={gotoLogin}  />,
        document.body
      )}



      <div className="container-fluid copybg">
        <div className="container mycontainer">
          <div className="row">
            <div className="col-md-11 col-sm-11 col-xs-12">
              <img src={images.copyimg} className="max" alt="Demand Edge"></img><br />©2023 Quation Solutions Pvt. Ltd., All rights reserved
            </div>
            <div className="col-md-1 col-sm-1 col-xs-12">
              <p id="bottom">
                <a href="#" onClick={scrollToTop} aria-label="DE" >
                  <iconify-icon icon="tabler:arrow-top-circle" className="scrollerbottom animate__animated animate__infinite animate__slideInUp"></iconify-icon>
                </a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
