import React, { useState, useRef } from 'react';
import { Tab, Tabs } from '../common/Tabs';
import { KeyBenefitsData } from '../../Utils/dataKeyBenefits';
import styled from 'styled-components';
import {icon3} from '../../assets/images'

function KeyBenefits() {

  const TabsRef = useRef();
  const [tabState, setTabState] = useState({ activeTab: KeyBenefitsData[0]?.Name });

  const changeTab = (tab) => {
   
    setTabState({ activeTab: tab });
  }

  if (KeyBenefitsData === null) {
    return false;
  }

  return (
    <Wrapper>
      <Tabs name="keyBenefitsTab" ref={TabsRef} changeHandler={changeTab} tabState={tabState} style={1}>
        {KeyBenefitsData?.map((kb, index) => (
          <Tab label={kb.Name} name={kb.Name} key={index}>
            <div>
              <div className="col-md-7 col-sm-7 col-xs-12">
                <p>{kb.Paragraph1}</p>
                <p>{kb.Paragraph2}</p>
              </div>
              <div className="col-md-5 col-sm-5 col-xs-12">
                <img src={kb.Image} className="max" alt="Demand Edge" />
              </div>
            </div>
          </Tab>
        ))}
      </Tabs>
    </Wrapper>
  )
}

export default KeyBenefits;


const Wrapper = styled.div`
#keyBenefitsTab .tabs {
  padding: 0px;
  margin: 60px auto 0px auto;
  text-align: center !important;
  list-style-type: none;
  z-index: 99;
  position: relative;
  width: 100%;
}

#keyBenefitsTab button {
  font-size: 15px;
  text-decoration: none;
  position: relative;
  padding: 24px 12px 20px 12px;
  color: #0C3C54;
  outline: none;
  font-weight: 500;
}

#keyBenefitsTab button:hover {
  border-radius: 10px;
  color: #fff;
}

#keyBenefitsTab p{
  text-align: left;
  width: 100% !important;
  font-size: 14px !important;
  margin: 10px 0px;
}

.tab-buttons button {
  font-size: 15px;
  text-decoration: none;
  position: relative;
  padding: 24px 12px 20px 12px;
  color: #0C3C54;
  outline: none;
  font-weight: 500;
  border-radius: 10px;
  transition:none !important;
}

#keyBenefitsTab .tab-buttons button:hover {
  border-radius: 10px;
  background-image: linear-gradient(to right, #0C3C54, #1B85BA);
  color: #fff;
}

#keyBenefitsTab .tab-buttons button.selected {
  color: #fff !important;
  height: 170px;
  display: inline-block;
  width: 222px;
  background-image: url(${icon3});
  background-repeat: no-repeat;
  background-position-y: 51px;
}

#keyBenefitsTab .tab-buttons button.selected:hover {
  color: #fff;
  height: 170px;
  display: inline-block;
  width: 222px;
  background-image: url(${icon3});
  background-repeat: no-repeat;
  background-position-y: 51px;
}

#keyBenefitsTab #tabContent {
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  align-content: center;
  margin: 0px auto;
  padding: 30px 30px;
  border-top: 3px solid #0C3C54;
  -webkit-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.12);
}

.tabs {
  font-family: Quicksand, Arial, sans-serif;
  margin: 0 auto;
  width: 70%;
  text-align: center;
  margin-top: 15vh;
}

.tab-buttons {
  margin-bottom: 1rem;
}



.tabs{
  font-family:Quicksand, Arial, sans-serif;
  margin:0 auto;
  width:70%;
  text-align:center;
  margin-top:15vh;
}

.tab-buttons{
  margin-bottom:1rem;
}

.tab-buttons button{
  margin-right:15px;
  font-size:20px;
  background:transparent;
  border:none;
  outline:none;
  padding:10px 20px ;
  cursor:pointer;
  color:rgba(0,0,0,.4);
  transition:all ease-in-out .2s;
  border-bottom:2px solid transparent;    
}

.tab-buttons button.active{
  border-bottom:2px solid var(--clr-primary-2); 
  color:var(--clr-primary-2);
}
`