import React from 'react'

export default function Stage5() {
  return (
    <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="createworkflowtabcontents">           
            <div id="stage5">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="table-responsive">
                        <table class="table promotiontable table-bordered">
                            <thead>
                                <tr>
                                  <th></th>
                                  <th>From Date </th>
                                  <th>To Date</th>
                                  <th>Product</th>
                                  <th>Geography</th>
                                  <th>Channel</th>
                                </tr>
                              </thead>
                            <tbody>
                              <tr>
                                  <td>
                                    <div class="checkbox">
                                        <label><input type="checkbox" value="" /></label>
                                    </div>
                                  </td>
                                  <td class="promotiondate">
                                    <input type="email" class="form-control" id="date" placeholder="21-11-2024" />
                                    <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                                  </td>
                                  <td class="promotiondate">
                                    <input type="email" class="form-control" id="date" placeholder="21-12-2024" />
                                    <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                                  </td>
                                  <td>
                                    Sencha
                                  </td>
                                  <td>
                                    <div>
                                        <select class="form-control" id="sel1">
                                            <option>Country</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select class="form-control" id="sel1">
                                            <option>Zone</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select class="form-control" id="sel1">
                                            <option>City</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                        </select>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                        <select class="form-control" id="sel1">
                                            <option>Wholesaler</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select class="form-control" id="sel1">
                                            <option>Manufacturer</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select class="form-control" id="sel1">
                                            <option>Retailer</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                            <option>sample</option>
                                        </select>
                                    </div>
                                  </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="checkbox">
                                      <label><input type="checkbox" value="" disabled /></label>
                                  </div>
                                </td>
                                <td class="promotiondate">
                                  <input type="email" class="form-control" id="date" placeholder="21-11-2024" disabled />
                                  <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                                </td>
                                <td class="promotiondate">
                                  <input type="email" class="form-control" id="date" placeholder="21-12-2024" disabled />
                                  <iconify-icon icon="simple-line-icons:calender"></iconify-icon>
                                </td>
                                <td>
                                    Daily Thali
                                </td>
                                <td>
                                  <div>
                                      <select class="form-control" id="sel1" disabled>
                                          <option>Country</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                      </select>
                                  </div>
                                  <div>
                                      <select class="form-control" id="sel1" disabled>
                                          <option>Zone</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                      </select>
                                  </div>
                                  <div>
                                      <select class="form-control" id="sel1" disabled>
                                          <option>City</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                      </select>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                      <select class="form-control" id="sel1" disabled>
                                          <option>Wholesaler</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                      </select>
                                  </div>
                                  <div>
                                      <select class="form-control" id="sel1" disabled>
                                          <option>Manufacturer</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                      </select>
                                  </div>
                                  <div>
                                      <select class="form-control" id="sel1" disabled>
                                          <option>Retailer</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                          <option>sample</option>
                                      </select>
                                  </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
     </div>
</div>
  )
}
