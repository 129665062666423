import React from 'react'

export default function Stage4() {
  return (
    <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="createworkflowtabcontents">         
            <div id="stage4">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="table-responsive">
                        <table class="table dataselectiontable table-bordered">
                            <thead>
                                <tr>
                                  <th>Selected Sku’s</th>
                                  <th>Selected Cities</th>
                                  <th>Selected Retailers</th>
                                  <th>Total<br/> Available Data</th>
                                  <th>Total<br/> Selected Data</th>
                                </tr>
                              </thead>
                            <tbody>
                              <tr>
                                  <td>
                                    <div class="daselecteditem">
                                        <ul>
                                            <li>Food</li>
                                            <li>Beverages</li>
                                            <li>Tea</li>
                                            <li>Green Tea</li>
                                            <li><span>Sencha</span></li>
                                        </ul>
                                     </div>
                                  </td>
                                  <td>
                                    <div class="daselecteditem">
                                        <ul>
                                            <li>India</li>
                                            <li>South India</li>
                                            <li><span>Pune</span></li>
                                        </ul>
                                     </div>
                                  </td>
                                  <td>
                                    <div class="daselecteditem">
                                        <ul>
                                            <li>Manufacturer 1</li>
                                            <li> Wholsaler 3</li>
                                            <li><span>Retailer 5</span></li>
                                        </ul>
                                     </div>
                                  </td>
                                  <td><span>1.99,789</span></td>
                                  <td><span>758</span></td>
                              </tr>
                              <tr>
                                <td>
                                    <div class="daselecteditem">
                                        <ul>
                                            <li>Food</li>
                                            <li>Combo Meal</li>
                                            <li><span>Daily Thali</span></li>
                                        </ul>
                                     </div>
                                  </td>
                                  <td>
                                    <div class="daselecteditem">
                                        <ul>
                                            <li>India</li>
                                            <li>North India</li>
                                            <li><span>Delhi</span></li>
                                        </ul>
                                     </div>
                                  </td>
                                <td>
                                    <div class="daselecteditem">
                                        <ul>
                                            <li>Manufacturer 2</li>
                                            <li> Wholsaler 5</li>
                                            <li><span>Retailer 9</span></li>
                                        </ul>
                                     </div>
                                  </td>
                                <td><span>99,789</span></td>
                                <td><span>445</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>
  )
}
