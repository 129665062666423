import React from 'react'

export default function Stage1() {
  return (
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="createworkflowtabcontents">
          <div id="stage1">
            <div class="col-md-12 col-sm-12 col-xs-12 padding">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group1">
                  <label for="email">Workflow Name<span>*</span></label>
                  <input type="email" class="form-control" id="email" placeholder="Enter Here" />
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="form-group1">
                <label for="email">Workflow Description<span>*</span></label>
                <input type="email" class="form-control" id="email" placeholder="Enter Here" />
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 space">
              <div class="form-group1">
                <label class="radio-inline"><input type="radio" name="optradio" defaultChecked /> Daily</label>
                <label class="radio-inline"><input type="radio" name="optradio" />Weekly</label>
                <label class="radio-inline"><input type="radio" name="optradio" />Monthly</label>
                <label class="radio-inline"><input type="radio" name="optradio" />Quarterly</label>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 padding">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group1">
                  <label htmlFor="file1">Forecast model file</label>
                  <input type="file" class="form-control" id="file1" placeholder="No File Choosen" />
                </div>
                <div class="choosefilediv">
                  <ul>
                    <li><a href="/sample_files/ForecastSheet.csv" download={true}><iconify-icon icon="material-symbols:download"></iconify-icon> Download Sample File</a></li>
                    <li>(File Type: PDF & CSV)</li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group1">
                  <label htmlFor="file2">Inventory File</label>
                  <input type="file" class="form-control" id="file2" placeholder="No File Choosen" />
                </div>
                <div class="choosefilediv">
                  <ul>
                    <li><a href="/sample_files/InventoryFile.csv" download={true}><iconify-icon icon="material-symbols:download"></iconify-icon> Download Sample File</a></li>
                    <li>(File Type: PDF & CSV)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 padding space1">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group1">
                  <label for="file3">EOQ file</label>
                  <input type="file" class="form-control" id="file3" placeholder="No File Choosen" />
                </div>
                <div class="choosefilediv">
                  <ul>
                    <li><a href="/sample_files/EOQFile.csv" download={true}><iconify-icon icon="material-symbols:download"></iconify-icon> Download Sample File</a></li>
                    <li>(File Type: PDF & CSV)</li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group1">
                  <label for="file4">Safety Stock</label>
                  <input type="file" class="form-control" id="file4" placeholder="No File Choosen" />
                </div>
                <div class="choosefilediv">
                  <ul>
                    <li><a href="/sample_files/SafetyStock.csv" download={true}><iconify-icon icon="material-symbols:download"></iconify-icon> Download Sample File</a></li>
                    <li>(File Type: PDF & CSV)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="form-group1">
                <div class="uploadbtn"><a href="#">Upload File <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
              </div>
            </div>
          </div>        
        </div>
      </div>
    </div>
  )
}
