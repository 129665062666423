import React from 'react'

export default function Stage6() {
  return (
    <div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="table-responsive">
          <table class="table dataselectiontable table-bordered">
            <thead>
              <tr>
                <th colspan="2">Listing For Selected Data </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="checkbox">
                    <label><input type="checkbox" value="" />ARIMA</label>
                  </div>
                </td>
                <td>
                  The ARIMA model is a statistical workhorse for analyzing time series data. It forecasts future trends by considering past values and accounting for inherent patterns like seasonality.
                </td>
              </tr>
              <tr>
                <td>
                  <div class="checkbox">
                    <label><input type="checkbox" value="" />AR</label>
                  </div>
                </td>
                <td>
                  An auto-regression model predicts future values in a time series by analyzing past observations. It assumes the future depends on a trend influenced by past data points.
                </td>
              </tr>
              <tr>
                <td>
                  <div class="checkbox">
                    <label><input type="checkbox" value="" />TBAT</label>
                  </div>
                </td>
                <td>
                  The TBATS model is a statistical technique for forecasting time series data. It tackles trends, seasonal patterns (including complex ones), and inherent errors in the data using a combination of methods: Trigonometric seasonality, Box-Cox transformation, ARIMA error handling, trend modeling, and seasonal components.
                </td>
              </tr>
              <tr>
                <td>
                  <div class="checkbox">
                    <label><input type="checkbox" value="" />PROPHET</label>
                  </div>
                </td>
                <td>
                  Prophet is a Facebook developed forecasting model designed for ease of use. It excels at capturing common time series features like holidays, weekends, and overall trends. It uses an additive model to make interpretable forecasts.
                </td>
              </tr>
              <tr>
                <td>
                  <div class="checkbox">
                    <label><input type="checkbox" value="" />SARIMAX</label>
                  </div>
                </td>
                <td>
                  The SARIMAX model is a statistical method for forecasting time series data. It builds upon ARIMA models by incorporating seasonal effects, allowing it to capture recurring patterns across specific time intervals.
                </td>
              </tr>
              <tr>
                <td>
                  <div class="checkbox">
                    <label><input type="checkbox" value="" />SARIMAX</label>
                  </div>
                </td>
                <td>
                  The Holt-Winters model is a forecasting technique for time series data. It captures underlying trends and seasonal patterns to predict future values, making it useful for scenarios with cyclical fluctuations.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
