import React, {  useContext } from 'react';
import MenuContext from './MenuContext'


export default function StageNavigator() {
    const { activeTab , setActiveTab } = useContext(MenuContext);

    const NextStep = (tab) => {
        var number = tab.match(/\d+/)[0]; 
        setActiveTab(`Stage${Number(number)+1}`);
    }

    const PreviousStep = () => {
        var number = activeTab.match(/\d+/)[0]; 
        setActiveTab(`Stage${Number(number)-1}`);
    }


    return (
         <div className='container mycontainer'>
            <div class="row">
               <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group1">
                    { !['Stage1','Stage7'].includes(activeTab) &&     <div class="prevbtn"><a onClick={PreviousStep}  > <iconify-icon icon="iconamoon:arrow-left-2-duotone"></iconify-icon>Previous</a></div>}
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group1">
                        { !['Stage7'].includes(activeTab) &&   <div class="nextbtn"><a onClick={()=>NextStep(activeTab)}>Next <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>}
                    </div>
                </div>
            </div>
            </div>
    )
}
