import React from 'react'
import Breadcrumb from '../components/common/Breadcrumb';

function ManageWorkflow() {
  return (
    <div>
         <Breadcrumb  PageName={'Manage Workflow'} />
    </div>
  )
}

export default ManageWorkflow;
