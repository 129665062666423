import React from 'react'
import '../../assets/css/css.css';
import * as images from '../../assets/images';
import { postRequest } from '../../services/DataRequestService'
import UserService from '../../services/UserService';

export default function SignupPopup({onClose , gotoLogin}) {

  

  return (
    <>
      {/* Overlay for faded background */}
      <div className="modal-overlay" onClick={onClose} ></div>
    <div id="signup" class="modal modal-signup in fade" style={{display:'block'}}>
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-body">
                  <div class="motit">
                      <button type="button" onClick={onClose} class="close"><iconify-icon icon="material-symbols:close"></iconify-icon></button>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="loginwhitebg loginwhitebg1">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                          <div class="loginiconbg">
                            <img src={images.loginicon1} class="max" alt="Demand Edge" /><br />Signup
                          </div>
                      </div>
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="col-md-12 col-sm-12 col-xs-12 padding">
                           <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group1">
                              <input type="email" class="form-control form-control1" id="email" placeholder="Your Name" />
                            </div>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group1">
                              <input type="email" class="form-control form-control1" id="email" placeholder="Your Mobile No." />
                            </div>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group1">
                              <input type="email" class="form-control form-control1" id="email" placeholder="Your Email Id" />
                            </div>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group1">
                              <input type="email" class="form-control form-control1" id="email" placeholder="Your Password" />
                            </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">  
                           <div class="form-group">
                              <div class="checkbox">
                                <label><input type="checkbox" />I agree to the Terms of Service and Privacy Policy.</label>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="connectbtn"><a href="#" aria-label="DE">Signup <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
                            </div>
                            <div class="noaccount">
                                <a href="#" aria-label="DE">Already Have An Account?</a>
                            </div>
                            <div class="createaccount">
                              <a onClick={gotoLogin} aria-label="DE">Login Now</a>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
          </div>
      </div>
    </div>
    </>
  )
}
