import React, {  useContext }  from 'react'
import MenuContext from './MenuContext'

export default function Stage7() {

  const { setActiveTab } = useContext(MenuContext);

  const EditButtonClickHandler = () => {
    setActiveTab('Stage6');
  }

  
  const SubmitButtonClickHandler = () => {
    
  }

  return (
    <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="createworkflowtabcontents">           
            <div id="stage7"> 
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="table-responsive">
                        <table class="table reviewtable table-bordered">
                          <tbody>
                               <tr>
                                    <td>
                                        Workflow Name
                                    </td>
                                    <td>
                                        Test
                                    </td>
                               </tr>
                               <tr>
                                    <td>
                                        Workflow Description      
                                    </td>
                                    <td>
                                        testes
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Start Date           
                                    </td>
                                    <td>
                                        01-03-2024
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Cut Off Date     
                                    </td>
                                    <td>
                                        13-03-2024
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        End Date      
                                    </td>
                                    <td>
                                        27-03-2024
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Granularity   
                                    </td>
                                    <td>
                                        Daily
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Models        
                                    </td>
                                    <td>
                                        ARIMA
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Data Selections     
                                    </td>
                                    <td>
                                        <div class="daselecteditem">
                                            <ul>
                                                <li>Manufacturer 1</li>
                                                <li> Wholsaler 3</li>
                                                <li><span>Retailer 5</span></li>
                                            </ul>
                                         </div>
                                    </td>
                                </tr>
                                </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 padding">
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="form-group1">
                            <div class="editbtn"><a onClick={EditButtonClickHandler}> <iconify-icon icon="iconamoon:arrow-left-2-duotone"></iconify-icon>EDIT</a></div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="form-group1">
                            <div class="subbtn"><a onClick={SubmitButtonClickHandler}>Submit <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>
  )
}
