import React, { useEffect } from 'react';
//import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import styled from 'styled-components';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import './assets/css/css.css';
import './assets/css/responisve.css';
import './assets/css/menu.css';
import './assets/css/flexslider.css';
import './assets/css/bannerslider.css';

import Dashboard from './pages/Dashboard';
import CreateWorkflow from './pages/CreateWorkflow';
import ManageWorkflow from './pages/ManageWorkflow';
import MyProfile from './pages/MyProfile';

import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';
import Page from './components/common/Page';


const router = createBrowserRouter(
  createRoutesFromElements(
    <>

      <Route element={<AppLayout />}  >

      <Route path="/"
          element={<Dashboard />}>
        </Route>

        <Route path="/Dashboard"
          element={<Dashboard />}>
        </Route>

        <Route path="/CreateWorkflow"
          element={<CreateWorkflow />}>
        </Route>

        <Route path="/ManageWorkflow"
          element={<ManageWorkflow />}>
        </Route>

        
        <Route path="/MyProfile"
          element={<MyProfile />}>
        </Route>

      </Route>
    </>
  )
);

function AppLayout() {


 
  return (
    <>
  {/* <ReactNotifications /> */}
      <Wrapper className="root">
        <Navbar />
        <Page />
        <Footer />
      </Wrapper>

    </>

  );
}

function App() {

  useEffect(() => {
 
    // Array of script sources
    const scripts = [
      'js/jquery.min.js',
      'js/js.js',
   
      'js/jquery.flexslider.js'
    ];

    // Function to load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(src);
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    // Load all scripts in order
    const loadScriptsSequentially = async () => {
      try {
        for (const script of scripts) {
          await loadScript(script);
        }
        console.log('All scripts loaded successfully.');
      } catch (error) {
        console.error(error);
      }
    };

    loadScriptsSequentially();

    // Cleanup function to remove scripts on component unmount
    return () => {
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  
  return (

    <RouterProvider router={router} />

  )
}

export default App;

const Wrapper = styled.div`  


`