import React from 'react';
import Breadcrumb from '../components/common/Breadcrumb'
import * as Images from '../assets/images';
import { Link } from 'react-router-dom';


function Dashboard() {
  return (
<>
<Breadcrumb PageName={'Dashboard'} />
<div className="container-fluid aftrlogincontainer2">
        <div className="container mycontainer">
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="afterlogcon1box1">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <img src={Images.alicon1} className="max" />
                        </div>
                        <div className="col-md-9 col-sm-8 col-xs-12">
                            <h3>Create Workflow</h3>
                            <p>Create a new workflow by defining the business criteria, model configuration and more...</p>
                            <div className="alborderbottom1"></div>
                            <div className="afterloginbtn1"><Link to="/CreateWorkflow"  state={ {pageName: 'Create Workflow'}} aria-label="DE">Get Started <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></Link></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="afterlogcon1box2">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <img src={Images.alicon2}className="max" />
                        </div>
                        <div className="col-md-9 col-sm-8 col-xs-12">
                            <h3>Manage Workflow</h3>
                            <p>You can view the list of workflows and there status here.</p>
                            <div className="alborderbottom2"></div>
                            <div className="afterloginbtn2">
                                <Link to="/ManageWorkflow" aria-label="DE">Get Started <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></Link></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="afterlogcon1box2">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <img src={Images.alicon2} className="max" />
                        </div>
                        <div className="col-md-9 col-sm-8 col-xs-12">
                            <h3>Manage Workflow</h3>
                            <p>You can view the list of workflows and there status here.</p>
                            <div className="alborderbottom2"></div>
                            <div className="afterloginbtn2"><Link to="/ManageWorkflow" aria-label="DE">Get Started <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></Link></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="afterlogcon1box1">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <img src={Images.alicon1} className="max" />
                        </div>
                        <div className="col-md-9 col-sm-8 col-xs-12">
                            <h3>Create Workflow</h3>
                            <p>Create a new workflow by defining the business criteria, model configuration and more...</p>
                            <div className="alborderbottom1"></div>
                            <div className="afterloginbtn1"><Link to="/CreateWorkflow" state={ {pageName: 'Create Workflow'}} aria-label="DE">Get Started <iconify-icon icon="iconamoon:arrow-right-2-bold"></iconify-icon></Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</>
  )
}

export default Dashboard;


